<template>
    <div class="modal" :class="{'is-active': $parent.show_job_dates_modal}">
        <div class="modal-background" @click.self="$parent.show_job_dates_modal = false"></div>
        <div class="modal-card">
            <section class="modal-card-body custom_scrollbar-1 p-0">
                <!-- <pre>
                    {{$parent.selected_job}}
                </pre> -->
                <div class="is-flex is-flex-direction-column ">
                    <div class="p-2 is-flex">
                        <div class="truck-icon has-text-white">
                            <img width="25px" height="25px" class="mr-1" src="/icons/return.gif">
                            Deliver starts on
                        </div>
                        <div class="flat-pickr-wrap ml-1" >
                            <flat-pickr 
                                v-model="departure_date" 
                                v-if="dates_available"
                                @on-change="save_job_dates()" 
                                :config="flatpicker_config" 
                                placeholder="Select a date"
                                class="input is-small form-control mr-2"
                                :class="{ 'is-danger' : !departure_date}"
                            ></flat-pickr>
                        </div> 
                        <!-- <date-picker 
                            :disabled="$parent.is_job_completed($parent.selected_job)"
                            @change="save_job_dates()"
                            v-model="departure_date"
                            type="date"
                            format="ddd DD.MM.YYYY"
                            :lang="$i18n.locale"
                            :input-class="{
                                'input is-small is-fullwidth': true, 
                                'is-danger': departure_date == null
                            }"
                            :disabled-date="(j) => {
                                var dt1 = new Date($parent.selected_job.DayTimeOut);
                                dt1.setDate( dt1.getDate() - 1 );
                                return (j > dt1)
                            }"
                        ></date-picker> -->
                    </div>
                    <div class="has-text-centered py-1 has-background-white-ter has-text-dark">
                        Event date <br>
                        {{$parent.selected_job.DayTimeOut | customdate}} - {{$parent.selected_job.DayTimeIn | customdate}} 
                    </div>
                    <div class="is-align-self-flex-end p-2 is-flex">
                        <div class="truck-icon has-text-white">
                            <img class="mr-1" width="25px" height="25px" src="/icons/return.gif" style="transform: scaleX(-1);">
                            Pickup ends on
                        </div>
                         <div class="flat-pickr-wrap ml-1" >
                            <flat-pickr 
                                v-model="pickup_date" 
                                v-if="dates_available"
                                @on-change="save_job_dates()" 
                                :config="flatpicker_config_2" 
                                placeholder="Select a date"
                                class="input is-small form-control mr-2"
                                :class="{ 'is-danger' : !departure_date}"
                                altInputClass="is-fullwidth"
                            ></flat-pickr>
                        </div> 
                        <!-- <date-picker 
                            @change="save_job_dates()"
                            v-model="pickup_date"
                            type="date"
                            format="ddd DD.MM.YYYY"
                            :lang="$i18n.locale"
                            :input-class="{
                                'input is-small is-fullwidth': true, 
                            }"
                            :disabled-date="(j) => {
                                if($parent.is_job_completed($parent.selected_job)){
                                    // Returning
                                }
                                var dt2 = new Date($parent.selected_job.DayTimeIn);
                                dt2.setDate( dt2.getDate());
                                return (j < dt2)
                            }"
                        ></date-picker> -->
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/event-bus'
// import {Default} from  'flatpickr/dist/l10n/default.js';
import {German} from  'flatpickr/dist/l10n/de.js';
import {Italian} from  'flatpickr/dist/l10n/it.js';
export default {
    data() {
        return {
            original_departure_date: null,
            original_pickup_date: null,
            departure_date: new Date(),
            pickup_date: new Date(),
            flatpicker_config: {
                altFormat: "D d.m.Y",
                dateFormat: "Y-m-d",
                altInput: true,
                altInputClass: 'border_col',
                wrap: true, 
                allowInput: true, 
                locale: (this.$i18n.locale =='de') ? German : (this.$i18n.locale == 'en') ? 'en' : Italian,
                disable:[{'from': new Date(this.$parent.selected_job.DayTimeOut).fp_incr(1), 'to': new Date(8640000000000000)}]
            },
             flatpicker_config_2: {
                dateFormat: "Y-m-d",
                altFormat: "D d.m.Y",
                altInput: true,
                altInputClass: 'border_col width-100',
                wrap: true, 
                allowInput: true, 
                locale: (this.$i18n.locale =='de') ? German : (this.$i18n.locale == 'en') ? 'en' : Italian,
                enable:[{'from': new Date(this.$parent.selected_job.DayTimeIn), 'to': new Date(8640000000000000)}]
            },
            dates_available: false,
        }
    },
    created(){
        this.get_proj_warehouse_job()

        EventBus.$on('save_dates', (data) => {
            console.log('modal data', data)
            this.departure_date = this.$moment(data.departure_date)
            this.pickup_date = this.$moment(data.pickup_date)
            
            this.original_departure_date = this.$moment(data.departure_date)
            this.original_pickup_date = this.$moment(data.pickup_date)
        })
    },
    destroyed: function() {
		EventBus.$off('save_dates', this.departure_date, this.pickup_date)
	},
    methods: {
        get_proj_warehouse_job(){
            axios.post(this.$tetris_server + '/get/proj/warehouse/job', {
                ej_id: this.$parent.selected_job.IdJob,
                project_id: this.$parent.$parent.$parent.event_details.IdProject,
            }).then(res => {
                //console.log('got this', res.data)
                if(res.data.length > 0){
                    this.original_departure_date = res.data[0].departure_date ? this.$moment(res.data[0].departure_date).format("YYYY-MM-DD") : null
                    this.original_pickup_date = res.data[0].pickup_date ? this.$moment(res.data[0].pickup_date).format("YYYY-MM-DD") : null

                    this.departure_date = res.data[0].departure_date ? this.$moment(res.data[0].departure_date).format("YYYY-MM-DD") : null
                    this.pickup_date = res.data[0].pickup_date ? this.$moment(res.data[0].pickup_date).format("YYYY-MM-DD") : null
                }
                this.dates_available = true
            })
        },

        save_job_dates(){
            var moment_departure = this.$moment(this.departure_date).format("YYYY-MM-DD")
            var moment_original_departure = this.$moment(this.original_departure_date).format("YYYY-MM-DD")
            var moment_pickup = this.$moment(this.pickup_date).format("YYYY-MM-DD")
            var moment_original_pickup = this.$moment(this.original_pickup_date).format("YYYY-MM-DD")
            
            if((this.$moment(moment_departure).isSame(moment_original_departure) == false || this.$moment(moment_pickup).isSame(moment_original_pickup) == false)){
                axios.post(this.$tetris_server + '/save/job/dates', {
                    ej_id: this.$parent.selected_job.IdJob,
                    project_id: this.$parent.$parent.$parent.event_details.IdProject,
                    departure_date: this.$moment(this.departure_date).format("YYYY-MM-DD"), // .add(2, "hours") ,
                    pickup_date: this.$moment(this.pickup_date).format("YYYY-MM-DD"), //.add(2, "hours") ,
                    job: this.$parent.selected_job
                }).then(res => {
                    console.log('rr', res.data[0])

                    this.original_departure_date = res.data[0].departure_date ? this.$moment(res.data[0].departure_date) : null
                    this.original_pickup_date = res.data[0].pickup_date ? this.$moment(res.data[0].pickup_date) : null

                    this.$parent.$parent.update_projects_warehouse_jobs(res.data)
                    this.$toast('Warehouse date saved')
                })
            }
        }
    },
}
</script>

<style>
.truck-icon{
	display: flex;
	align-items: center;
	justify-content: center
}
</style>
